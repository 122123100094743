import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React, { FC } from "react";
import Layout from "../components/layout";
import { SEO } from "../components/seo";

interface Props {
  pageContext: {
    langKey: "en" | "vn";
  };
}

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "8d04ba24-b12a-461b-bd86-df3aa0f07016",
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const IndexPage: FC<Props> = ({ pageContext, ...rest }) => {
  return (
    <>
      <SEO title="Home" />
      <Layout langKey={pageContext.langKey} />
    </>
  );
};

export default IndexPage;
